import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';
import asyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import { DateTime } from 'luxon';
import React from 'react';
import { toast } from 'react-toastify';
import { AppThunk } from '../../context/store';
import {
    IncidentsHistoryPropMap,
    IncidentsHistoryTitlePropMap,
    RosterCSVPropMap,
    RosterCSVTitlePropMap
} from '../../lib/dictionaries/IncidentsHistoryDictionary';
import {
    SEARCH_INCIDENTS_API_ENDPOINT,
    SEARCH_ROSTER_API_ENDPOINT,
    SORT_ORDER_DIRECTIONS
} from '../../lib/globals';
import { ColumnProps, createCSV } from '../../lib/helpers/CSVHelper';
import { formatToLocalTime } from '../../lib/helpers/helpers';
import { Incident, IncidentHistory, Roster } from '../../lib/types';
import { Logger } from '../../lib/utils/logger';

declare global {
    interface Navigator {
        msSaveBlob?: (blob: Blob, fileName: string) => boolean;
    }
}

const { doRequest } = asyncRequest;

interface IncidentsHistoryState {
    incidentsHistory: IncidentHistory[];
    totalCount: number;
    pageSize: number;
    pageNumber: number;
    filterAttributes: SearchIncidentsFilterAttributes | null;
    sortOrder: SearchIncidentsSortOrder;
    isLoadingIncidentsHistory: boolean;
    searchIncidentsHistoryError: string;
    isLoadingRoster: boolean;
    searchRosterError: string;
}

export const initialState: IncidentsHistoryState = {
    incidentsHistory: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 50,
    filterAttributes: { isOpen: false, closedFor: { time: 60, format: 'MINUTE' } },
    sortOrder: [{ column: IncidentsHistoryPropMap.startTime, order: SORT_ORDER_DIRECTIONS.DESCENDING }],
    isLoadingIncidentsHistory: false,
    searchIncidentsHistoryError: '',
    isLoadingRoster: false,
    searchRosterError: ''
};

const incidentsHistorySlice = createSlice({
    name: 'incidentsHistory',
    initialState,
    reducers: {
        startSearchIncidentsHistory: (state) => {
            const { incidentsHistory, searchIncidentsHistoryError } = initialState;
            state.incidentsHistory = incidentsHistory;
            state.searchIncidentsHistoryError = searchIncidentsHistoryError;
            state.isLoadingIncidentsHistory = true;
        },
        searchIncidentsHistorySucceeded: (
            state,
            {
                payload
            }: PayloadAction<{
                incidentsHistory: IncidentHistory[];
                pageSize: number;
                pageNumber: number;
                totalCount: number;
                filterAttributes: SearchIncidentsFilterAttributes | null;
                sortOrder: SearchIncidentsSortOrder;
            }>
        ) => {
            const {
                incidentsHistory,
                totalCount,
                pageNumber,
                pageSize,
                filterAttributes,
                sortOrder
            } = payload;
            state.isLoadingIncidentsHistory = false;
            state.incidentsHistory = incidentsHistory;
            state.totalCount = totalCount;
            state.pageSize = pageSize;
            state.pageNumber = pageNumber;
            state.filterAttributes = filterAttributes;
            state.sortOrder = sortOrder;
        },
        searchIncidentsHistoryFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isLoadingIncidentsHistory = false;
            state.searchIncidentsHistoryError = error;
        },
        startSearchRoster: (state) => {
            const { searchRosterError } = initialState;
            state.searchRosterError = searchRosterError;
            state.isLoadingRoster = true;
        },
        searchRosterSucceeded: (state) => {
            state.isLoadingRoster = false;
        },
        searchRosterFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.searchRosterError = error;
            state.isLoadingRoster = false;
        }
    }
});

export const {
    startSearchIncidentsHistory,
    searchIncidentsHistorySucceeded,
    searchIncidentsHistoryFailed,
    startSearchRoster,
    searchRosterSucceeded,
    searchRosterFailed
} = incidentsHistorySlice.actions;

export default incidentsHistorySlice.reducer;

interface IncidentsHistoryResult {
    totalCount: number;
    incidentsHistory: IncidentHistory[];
}

export interface SearchIncidentsSortOrder {
    [index: number]: {
        column: string;
        order: string;
    };
}

export interface SearchIncidentsFilterAttributes {
    [name: string]:
        | string
        | number
        | boolean
        | { time: number; format: string }
        | { start?: number | null; end?: number | null };
}

interface SearchIncidentsRequest {
    incidentIds?: string[];
    siteIds?: string[];
    siteName?: string[];
    filterAttributes?: SearchIncidentsFilterAttributes | null;
    rosterSearch?: boolean;
    pageSize?: number;
    pageNumber?: number;
    sortOrder?: SearchIncidentsSortOrder;
}

const searchIncidentsHistoryHelper = async (
    pageNumber: number,
    pageSize: number,
    filterAttributes: SearchIncidentsFilterAttributes | null,
    sortOrder: SearchIncidentsSortOrder
): Promise<IncidentsHistoryResult> => {
    const request: SearchIncidentsRequest = {
        pageNumber,
        pageSize,
        filterAttributes,
        sortOrder
    };
    const response = await doRequest(
        SEARCH_INCIDENTS_API_ENDPOINT,
        request,
        GuardianAuth.createRequestAuthHeader()
    );
    const result = JSON.parse(response.data.body);
    const { incidentData, totalCount } = result;
    const incidentsHistory: IncidentHistory[] = [];
    let country: string | undefined;
    incidentData.map((incident: Incident) => {
        const {
            id,
            sites,
            startTime,
            endTime,
            incidentType,
            incidentNewType,
            incidentSubType,
            incidentCommander,
            duration,
            totalOnsite,
            totalAccountedFor,
            pctAccountedFor,
            totalVisitors,
            countAddedToRoster
        } = incident;
        if (sites && sites.length) {
            country = sites[0].country;
        }
        incidentsHistory.push({
            id,
            country,
            siteName: sites?.map((site) => site.siteName)?.join(', '),
            startTime: formatToLocalTime(DateTime.fromISO(startTime, { zone: 'utc' })),
            endTime: formatToLocalTime(DateTime.fromISO(endTime, { zone: 'utc' })),
            incidentTypeName: incidentType?.name,
            incidentNewTypeName: incidentNewType?.name,
            incidentSubTypeName: incidentSubType?.name,
            eventOwner: incidentCommander, // patch to convert IC to EO from backend.
            duration: duration,
            onSite: totalOnsite,
            numberAccounted: totalAccountedFor,
            percentAccounted: Math.floor(pctAccountedFor),
            totalVisitors: totalVisitors,
            countAddedToRoster: countAddedToRoster
        });
    });
    return { totalCount, incidentsHistory };
};

export const searchIncidentsHistoryAsync = (
    pageNumber: number,
    pageSize: number,
    filterAttributes: SearchIncidentsFilterAttributes | null,
    sortOrder: SearchIncidentsSortOrder
): AppThunk => async (dispatch) => {
    try {
        dispatch(startSearchIncidentsHistory());
        const result = await searchIncidentsHistoryHelper(pageNumber, pageSize, filterAttributes, sortOrder);
        const { incidentsHistory, totalCount } = result;
        dispatch(
            searchIncidentsHistorySucceeded({
                incidentsHistory,
                pageSize,
                pageNumber,
                totalCount,
                filterAttributes,
                sortOrder
            })
        );
    } catch (error) {
        let message = error.message;
        const { response } = error;
        if (response) {
            const parsedBody = JSON.parse(response.data.body);
            const { error: returnedErrorMessage } = parsedBody;
            message = returnedErrorMessage;
        }
        dispatch(searchIncidentsHistoryFailed({ error: message }));
        toast.error(
            <ToastErrorMessage header={'Search events history failed'} errorMessage={error.message} />
        );
    }
};

export const downloadIncidentsHistoryCSVAsync = (
    filename: string,
    filterAttributes: SearchIncidentsFilterAttributes | null,
    sortOrder: SearchIncidentsSortOrder
): AppThunk => async (dispatch) => {
    const toastId = toast.info('Starting to download Events CSV');
    try {
        let totalCount = -1;
        const pageSize = 50;
        const fullIncidentsHistory: IncidentHistory[] = [];
        for (let pageNumber = 1; pageNumber == 1 || totalCount > (pageNumber - 1) * pageSize; pageNumber++) {
            const result = await searchIncidentsHistoryHelper(
                pageNumber,
                pageSize,
                filterAttributes,
                sortOrder
            );
            const { incidentsHistory, totalCount: returnedTotalCount } = result;
            fullIncidentsHistory.push(...incidentsHistory);
            totalCount = returnedTotalCount;
        }
        const columnProps: ColumnProps = {
            columns: [
                IncidentsHistoryTitlePropMap.id,
                IncidentsHistoryTitlePropMap.country,
                IncidentsHistoryTitlePropMap.siteName,
                IncidentsHistoryTitlePropMap.startTime,
                IncidentsHistoryTitlePropMap.endTime,
                IncidentsHistoryTitlePropMap.incidentNewType,
                IncidentsHistoryTitlePropMap.incidentSubType,
                IncidentsHistoryTitlePropMap.eventOwner,
                IncidentsHistoryTitlePropMap.length,
                IncidentsHistoryTitlePropMap.onSite,
                IncidentsHistoryTitlePropMap.numberAccounted,
                IncidentsHistoryTitlePropMap.percentAccounted,
                IncidentsHistoryTitlePropMap.totalVisitors,
                IncidentsHistoryTitlePropMap.countAddedToRoster
            ],
            columnDataMap: {
                [IncidentsHistoryTitlePropMap.id]: IncidentsHistoryPropMap.id,
                [IncidentsHistoryTitlePropMap.country]: IncidentsHistoryPropMap.country,
                [IncidentsHistoryTitlePropMap.siteName]: IncidentsHistoryPropMap.siteName,
                [IncidentsHistoryTitlePropMap.startTime]: IncidentsHistoryPropMap.startTime,
                [IncidentsHistoryTitlePropMap.endTime]: IncidentsHistoryPropMap.endTime,
                [IncidentsHistoryTitlePropMap.incidentNewType]: IncidentsHistoryPropMap.incidentNewType,
                [IncidentsHistoryTitlePropMap.incidentSubType]: IncidentsHistoryPropMap.incidentSubType,
                [IncidentsHistoryTitlePropMap.eventOwner]: IncidentsHistoryPropMap.eventOwner,
                [IncidentsHistoryTitlePropMap.length]: IncidentsHistoryPropMap.length,
                [IncidentsHistoryTitlePropMap.onSite]: IncidentsHistoryPropMap.onSite,
                [IncidentsHistoryTitlePropMap.numberAccounted]: IncidentsHistoryPropMap.numberAccounted,
                [IncidentsHistoryTitlePropMap.percentAccounted]: IncidentsHistoryPropMap.percentAccounted,
                [IncidentsHistoryTitlePropMap.totalVisitors]: IncidentsHistoryPropMap.totalVisitors,
                [IncidentsHistoryTitlePropMap.countAddedToRoster]: IncidentsHistoryPropMap.countAddedToRoster
            }
        };
        const csvContent = await createCSV(fullIncidentsHistory, columnProps);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('visibility', 'hidden');
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        toast.update(toastId, { render: 'Downloaded Event CSV Successfully', type: toast.TYPE.SUCCESS });
        Logger.info(`Downloaded Event History Successfully`);
    } catch (error) {
        dispatch(searchIncidentsHistoryFailed({ error: error.message }));
        toast.update(toastId, {
            render: <ToastErrorMessage header={'Failed to Download Roster'} errorMessage={error.message} />,
            type: toast.TYPE.ERROR,
            autoClose: false
        });
        Logger.error(`Failed to download Event History with error: ${error.message}`);
    }
};

const downloadRosterCSV = (rosterList: Roster[], filename: string): void => {
    const rows: { [key: string]: string | number | boolean | null }[] = [];
    for (const entry of rosterList) {
        const {
            empId,
            login,
            firstName,
            lastName,
            managerLogin,
            accountedFor,
            lastSeen,
            escortLogin,
            isVisitor,
            imtMembership,
            visitorCompany,
            lastBadgePoint,
            accountedBy,
            checkInMethod,
            lastBadgeTime,
            lastAccountedTime
        } = entry;
        rows.push({
            name: `${firstName} ${lastName}`,
            login,
            managerLogin,
            empId,
            imtMembership: imtMembership.sort().join(', '),
            lastSeen,
            lastBadgePoint,
            personnelType: isVisitor ? 'Visitor' : 'Employee',
            company: visitorCompany,
            escortLogin,
            accountedFor,
            accountedBy: accountedBy ? accountedBy.login : '',
            accountedForType: checkInMethod?.name ? checkInMethod?.name : null,
            lastBadgeTime: lastBadgeTime
                ? formatToLocalTime(DateTime.fromISO(lastBadgeTime, { zone: 'utc' }))
                : '',
            lastAccountedTime: lastAccountedTime
                ? formatToLocalTime(DateTime.fromISO(lastAccountedTime, { zone: 'utc' }))
                : ''
        });
    }
    const columnProps: ColumnProps = {
        columns: [
            RosterCSVTitlePropMap.name,
            RosterCSVTitlePropMap.login,
            RosterCSVTitlePropMap.managerLogin,
            RosterCSVTitlePropMap.empId,
            RosterCSVTitlePropMap.imtMembership,
            RosterCSVTitlePropMap.lastBadgeTime,
            RosterCSVTitlePropMap.lastBadgePoint,
            RosterCSVTitlePropMap.personnelType,
            RosterCSVTitlePropMap.company,
            RosterCSVTitlePropMap.entryDate,
            RosterCSVTitlePropMap.escortName,
            RosterCSVTitlePropMap.escortLogin,
            RosterCSVTitlePropMap.accountedFor,
            RosterCSVTitlePropMap.accountedBy,
            RosterCSVTitlePropMap.lastAccountedTime,
            RosterCSVTitlePropMap.accountedForType
        ],
        columnDataMap: {
            [RosterCSVTitlePropMap.name]: RosterCSVPropMap.name,
            [RosterCSVTitlePropMap.login]: RosterCSVPropMap.login,
            [RosterCSVTitlePropMap.managerLogin]: RosterCSVPropMap.managerLogin,
            [RosterCSVTitlePropMap.empId]: RosterCSVPropMap.empId,
            [RosterCSVTitlePropMap.imtMembership]: RosterCSVPropMap.imtMembership,
            [RosterCSVTitlePropMap.lastBadgeTime]: RosterCSVPropMap.lastBadgeTime,
            [RosterCSVTitlePropMap.lastAccountedTime]: RosterCSVPropMap.lastAccountedTime,
            [RosterCSVTitlePropMap.lastBadgePoint]: RosterCSVPropMap.lastBadgePoint,
            [RosterCSVTitlePropMap.personnelType]: RosterCSVPropMap.personnelType,
            [RosterCSVTitlePropMap.company]: RosterCSVPropMap.company,
            [RosterCSVTitlePropMap.entryDate]: RosterCSVPropMap.entryDate,
            [RosterCSVTitlePropMap.escortName]: RosterCSVPropMap.escortName,
            [RosterCSVTitlePropMap.escortLogin]: RosterCSVPropMap.escortLogin,
            [RosterCSVTitlePropMap.accountedFor]: RosterCSVPropMap.accountedFor,
            [RosterCSVTitlePropMap.accountedBy]: RosterCSVPropMap.accountedBy,
            [RosterCSVTitlePropMap.accountedForType]: RosterCSVPropMap.accountedForType
        }
    };
    const csvContent = createCSV(rows, columnProps);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('visibility', 'hidden');
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

export const downloadRosterCSVAsync = (incidentId: string): AppThunk => async (dispatch) => {
    const toastId = toast.info('Starting to download Roster CSV');
    try {
        dispatch(startSearchRoster());
        let totalCount = -1;
        const pageSize = 100;
        const fullRoster: Roster[] = [];
        for (let pageNumber = 1; pageNumber == 1 || totalCount > (pageNumber - 1) * pageSize; pageNumber++) {
            const request = {
                incidentId,
                pageNumber,
                pageSize
            };
            const response = await doRequest(
                SEARCH_ROSTER_API_ENDPOINT,
                request,
                GuardianAuth.createRequestAuthHeader()
            );
            const result = JSON.parse(response.data.body);
            const { roster, totalCount: returnedTotalCount } = result;
            totalCount = returnedTotalCount;
            fullRoster.push(...roster);
            toast.update(toastId, { render: 'Downloaded Roster CSV Successfully', type: toast.TYPE.SUCCESS });
            Logger.info(`Downloaded Roster Successfully`);
        }
        dispatch(searchRosterSucceeded());
        downloadRosterCSV(fullRoster, `roster-${incidentId}.csv`);
    } catch (error) {
        dispatch(searchRosterFailed({ error: error.message }));
        toast.update(toastId, {
            render: <ToastErrorMessage header={'Failed to Download Roster'} errorMessage={error.message} />,
            type: toast.TYPE.ERROR,
            autoClose: false
        });
        Logger.error(`Failed to download Roster with error: ${error.message}`);
    }
};

export const downloadFilteredRosterCSVAsync = (
    incidentId: string,
    sortOrder: [{ column: string; order: string }] | undefined,
    filterAttributes: unknown
): AppThunk => async (dispatch) => {
    const toastId = toast.info('Starting to download Filtered Roster CSV');
    try {
        dispatch(startSearchRoster());
        let totalCount = -1;
        const pageSize = 100;
        const fullRoster: Roster[] = [];
        for (let pageNumber = 1; pageNumber == 1 || totalCount > (pageNumber - 1) * pageSize; pageNumber++) {
            const request = {
                incidentId,
                pageSize,
                pageNumber,
                sortOrder,
                filterAttributes
            };
            const response = await doRequest(
                SEARCH_ROSTER_API_ENDPOINT,
                request,
                GuardianAuth.createRequestAuthHeader()
            );
            const result = JSON.parse(response.data.body);
            const { roster, totalCount: returnedTotalCount } = result;
            totalCount = returnedTotalCount;
            fullRoster.push(...roster);
            toast.update(toastId, { render: 'Downloaded Roster CSV Successfully', type: toast.TYPE.SUCCESS });
        }
        dispatch(searchRosterSucceeded());
        downloadRosterCSV(fullRoster, `roster-${incidentId}.csv`);
    } catch (error) {
        dispatch(searchRosterFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage
                    header={'Failed to Download Filtered Roster'}
                    errorMessage={error.message}
                />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};
